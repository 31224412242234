import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Power Up Your Command Line",
  "date": "2019-01-03T18:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/tools/",
  "category": "Software",
  "tags": ["productivity", "command line", "tips"],
  "description": "A showcase of some of the best non-standard command line tools I've discovered in recent years. If you use the command line, you'll probably find at least one item on this page that will make your life easier."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This post is part one of a series of showcases of some of the best non-standard command line tools I've discovered in recent years. If you use the command line, you'll probably find at least one thing on this page that will make your life easier.`}</p>
    <h2><inlineCode parentName="h2">{`z`}</inlineCode>{`, jump around`}</h2>
    <p>{`One of the best and most ubiquitous time-saving features in modern web browsers is the fuzzy and smart search capabilities of the address bar. Want to quickly go to Twitter? Typing "tw" into your address bar and hitting enter is probably enough.`}</p>
    <p>{`By comparison, the standard way of navigating our file systems via the command line (using `}<inlineCode parentName="p">{`cd`}</inlineCode>{`) seems almost prehistoric. Thankfully, `}<inlineCode parentName="p">{`z`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/rupa/z"
      }}>{`GitHub`}</a>{`) brings this browser-style navigation to the command line.`}</p>
    <p><img parentName="p" {...{
        "src": "/987b878944e690843afd549b81a76be6/jump_around.svg",
        "alt": "Jump Around"
      }}></img></p>
    <p>{`After a short learning period, `}<inlineCode parentName="p">{`z`}</inlineCode>{` will allow you to jump to a directory `}<em parentName="p">{`from anywhere`}</em>{`, using only a substring of the target directory name. The directory that `}<inlineCode parentName="p">{`z`}</inlineCode>{` will take you to is determined by the string argument you gave it, how `}<em parentName="p">{`frequently`}</em>{` you visit the directory, and how `}<em parentName="p">{`recently`}</em>{` you visited the directory. They call it `}<em parentName="p">{`"frecency"`}</em>{`.`}</p>
    <p><inlineCode parentName="p">{`z`}</inlineCode>{` not only increases the speed at which you can navigate your filesystem, it reduces the cognitive load of navigation. With `}<inlineCode parentName="p">{`cd`}</inlineCode>{`, you need to recall precisely where the destination directory sits in the tree, and work out the path to get there. With `}<inlineCode parentName="p">{`z`}</inlineCode>{`, knowing the name of the directory is enough.`}</p>
    <p>{`Ports of `}<inlineCode parentName="p">{`z`}</inlineCode>{` to other shells (such as fish and zsh) are readily available too. `}<inlineCode parentName="p">{`autojump`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/wting/autojump"
      }}>{`GitHub`}</a>{`) is a similar project.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`z`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`Install `}<inlineCode parentName="li">{`bash`}</inlineCode>{` version, on macOS (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install z`}</inlineCode></li>
      <li parentName="ul">{`Install `}<inlineCode parentName="li">{`fish`}</inlineCode>{` shell `}<a parentName="li" {...{
          "href": "https://github.com/jethrokuan/z"
        }}>{`version`}</a>{`, on macOS (`}<a parentName="li" {...{
          "href": "https://github.com/jorgebucaran/fisher"
        }}>{`Fisher`}</a>{`): `}<inlineCode parentName="li">{`fisher add jethrokuan/z`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`fzf`}</inlineCode>{`, a fast fuzzy finder`}</h2>
    <p>{`After installing `}<inlineCode parentName="p">{`fzf`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/junegunn/fzf"
      }}>{`GitHub`}</a>{`), you can press `}<inlineCode parentName="p">{`Ctrl + T`}</inlineCode>{` at any point in time to open up an interactive fuzzy search interface, which will recursively search for files inside the current directory. You can enter a search term, and press the up/down keys to move through the results. If you press the enter key, the selected result is inserted into your terminal:`}</p>
    <p><img parentName="p" {...{
        "src": "/54dfd83c8f95d32152c9c2da2bba488d/fzf.svg",
        "alt": "fzf"
      }}></img></p>
    <p>{`In the example above, I typed `}<inlineCode parentName="p">{`bat`}</inlineCode>{` (but this could be any command, such as `}<inlineCode parentName="p">{`less`}</inlineCode>{`, `}<inlineCode parentName="p">{`cd`}</inlineCode>{`, etc.), then pressed `}<inlineCode parentName="p">{`Ctrl + T`}</inlineCode>{`. I typed `}<inlineCode parentName="p">{`five`}</inlineCode>{`, hit enter, and the path `}<inlineCode parentName="p">{`src/five.rs`}</inlineCode>{` was inserted at my cursor position. This saves the alternative of (roughly): type `}<inlineCode parentName="p">{`src`}</inlineCode>{`, press tab, type `}<inlineCode parentName="p">{`fi`}</inlineCode>{`, press tab, which doesn't scale to long or hard to remember paths.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`fzf`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install fzf`}</inlineCode></li>
      <li parentName="ul">{`Bindings for `}<inlineCode parentName="li">{`fish`}</inlineCode>{`: `}<inlineCode parentName="li">{`fisher add jethrokuan/fzf`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`bat`}</inlineCode>{`, to view files with syntax highlighting`}</h2>
    <p>{`If you want to quickly view a source file with full syntax highlighting, `}<inlineCode parentName="p">{`bat`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/sharkdp/bat"
      }}>{`GitHub`}</a>{`) is your friend. `}<inlineCode parentName="p">{`bat`}</inlineCode>{` can be used as a drop-in replacement for `}<inlineCode parentName="p">{`cat`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/ff214ee3bcfdc35c6cf67e95f4b3004c/bat.svg",
        "alt": "bat"
      }}></img></p>
    <p>{`If the output is large enough (as in the example above), `}<inlineCode parentName="p">{`bat`}</inlineCode>{` will pipe it's output into `}<inlineCode parentName="p">{`less`}</inlineCode>{`, meaning we get pagination for free!`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`bat`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install bat`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`bench`}</inlineCode>{`, for benchmarking your code`}</h2>
    <p><inlineCode parentName="p">{`bench`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/Gabriel439/bench"
      }}>{`GitHub`}</a>{`) is an incredibly useful tool for benchmarking your code. It's written in Haskell, which makes it the coolest thing on this page. You can pass any command you can run from your terminal to it (in quotes), and it will measure the execution time by repeatedly running the command. When it's finished, it'll output useful statistics to your terminal.`}</p>
    <p><img parentName="p" {...{
        "src": "/bfdf5ac0c5538d3c068390d2e42ac95e/bench.svg",
        "alt": "bench"
      }}></img></p>
    <p>{`This is a considerably more powerful means of measuring the execution time of your code than the built-in `}<inlineCode parentName="p">{`time`}</inlineCode>{` command.`}</p>
    <p><inlineCode parentName="p">{`hyperfine`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/sharkdp/hyperfine"
      }}>{`GitHub`}</a>{`) is an alternative to `}<inlineCode parentName="p">{`bench`}</inlineCode>{` written in Rust that you might also be interested in.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`bench`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS using `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install bench`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`asciinema`}</inlineCode>{` & `}<inlineCode parentName="h2">{`svg-term`}</inlineCode>{`, for recording your terminal as an SVG animation`}</h2>
    <p>{`The terminal clips on this page are actually SVG animations! Using SVG rather than a video format has several huge benefits:`}</p>
    <ul>
      <li parentName="ul">{`Perfect quality regardless of zoom 🔍`}</li>
      <li parentName="ul">{`We can put them in Markdown files like any other image 😱`}</li>
      <li parentName="ul">{`Smaller file sizes compared to video formats 🧐`}</li>
      <li parentName="ul">{`SVG animations are `}<em parentName="li">{`way`}</em>{` cooler than videos 🔥`}</li>
    </ul>
    <p>{`To record the terminal, I use `}<a parentName="p" {...{
        "href": "https://asciinema.org"
      }}><inlineCode parentName="a">{`asciinema`}</inlineCode></a>{`. Begin recording with `}<inlineCode parentName="p">{`asciinema rec`}</inlineCode>{`. When you're finished, press `}<inlineCode parentName="p">{`Ctrl+D`}</inlineCode>{`, and you'll be given the options of either saving the recording locally or uploading it to `}<a parentName="p" {...{
        "href": "https://asciinema.org"
      }}>{`asciinema.org`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/e1488b9806f360b88391a244d2354cf2/asciinema_example.svg",
        "alt": "asciinema_example"
      }}></img></p>
    <p>{`If you want to generate an SVG animation from your recording using `}<inlineCode parentName="p">{`svg-term`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/marionebl/svg-term-cli"
      }}>{`GitHub`}</a>{`), and you uploaded your recording to asciinema, you'll have to make it public by visiting the resulting link.`}</p>
    <p>{`To convert the recording to an SVG animation, you can supply the ID of the cast (available on the asciinema page after making it public - the ID is in the URL), an output file, and numerous other optional arguments. For example, to save the terminal recording at `}<a parentName="p" {...{
        "href": "https://asciinema.org/a/219486"
      }}>{`https://asciinema.org/a/219486`}</a>{` to the SVG file you see in the example above, I used:`}</p>
    <pre><code parentName="pre" {...{}}>{`svg-term --cast=219486 --out ~/somewhere/out.svg --padding 18 --height 8 --width 80
`}</code></pre>
    <p>{`Alternatively, if you don't want to upload your recording to asciinema, you can supply a local cast file directly to `}<inlineCode parentName="p">{`svg-term`}</inlineCode>{` (thanks to Mario Nebl, the author of svg-term-cli for pointing this out to me):`}</p>
    <pre><code parentName="pre" {...{}}>{`asciinema rec cast.json
cat cast.json | svg-term-cli
`}</code></pre>
    <h3>{`Installing `}<inlineCode parentName="h3">{`asciinema`}</inlineCode>{` & `}<inlineCode parentName="h3">{`svg-term`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`Installing `}<inlineCode parentName="li">{`asciinema`}</inlineCode>{` on macOS: `}<inlineCode parentName="li">{`brew install asciinema`}</inlineCode></li>
      <li parentName="ul">{`Installing `}<inlineCode parentName="li">{`svg-term`}</inlineCode>{` on macOS: `}<inlineCode parentName="li">{`npm install -g svg-term-cli`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`wrk`}</inlineCode>{`, for benchmarking your HTTP APIs`}</h2>
    <p>{`This is a handy little tool for performance testing your API. To demonstrate, I've got a minimal Python HTTP API server with a single endpoint (`}<inlineCode parentName="p">{`/hello`}</inlineCode>{`) running on my local machine at port 8001. We can check how well the `}<inlineCode parentName="p">{`/hello`}</inlineCode>{` endpoint performs using `}<inlineCode parentName="p">{`wrk`}</inlineCode>{` (with 12 threads, 200 connections, for 5 seconds):`}</p>
    <p><img parentName="p" {...{
        "src": "/f2cef144344b219b0ed3398ea1727cb3/wrk.svg",
        "alt": "wrk"
      }}></img></p>
    <p>{`You can tweak the number of threads, connections, and the duration of your test to check performance under different loads. It's not a replacement for performance testing tools such as Locust and JMeter, but it's lightweight and will suffice in many situations.`}</p>
    <p>{`Unfortunately, the command line interface for `}<inlineCode parentName="p">{`wrk`}</inlineCode>{` makes it somewhat awkward to perform POST requests. If you want to do that, you'll need to write a small Lua script and supply it to the command as an argument (there's more information in the docs).`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`wrk`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS using `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install wrk`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`exa`}</inlineCode>{`, an alternative to `}<inlineCode parentName="h2">{`ls`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "https://the.exa.website"
      }}><inlineCode parentName="a">{`exa`}</inlineCode></a>{` is a modern replacement for `}<inlineCode parentName="p">{`ls`}</inlineCode>{` with colour coded output that's a little easier on the eye, and a larger variety of options for controlling how output is presented.`}</p>
    <p><img parentName="p" {...{
        "src": "/c640a48d016bf7391d213c660b4fbe8a/exa.svg",
        "alt": "Exa"
      }}></img></p>
    <p>{`It supports features such as respecting your `}<em parentName="p">{`.gitignore`}</em>{` files via the `}<inlineCode parentName="p">{`--git-ignore`}</inlineCode>{` flag, and printing
out a directory as a tree structure with the `}<inlineCode parentName="p">{`-T`}</inlineCode>{` flag (see above).`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`exa`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS using `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install exa`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`fd`}</inlineCode>{`, for finding files & directories`}</h2>
    <p>{`If you're looking for a file or directory, you'd usually use the `}<inlineCode parentName="p">{`find`}</inlineCode>{` command to perform a search based on a regular expression. `}<inlineCode parentName="p">{`fd`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/sharkdp/fd"
      }}>{`GitHub`}</a>{`) is an alternative to `}<inlineCode parentName="p">{`find`}</inlineCode>{` written in Rust which offers a more convenient interface by using sensible defaults, and it's faster to boot. `}</p>
    <p><img parentName="p" {...{
        "src": "/a358bee3a37ed6740896446863857a5f/fd.svg",
        "alt": "fd"
      }}></img></p>
    <p>{`It'll respect your `}<em parentName="p">{`.gitignore`}</em>{` files, and it supports parallel command execution, which lets you execute a terminal command (in parallel) for every file or directory returned for a search. For example (from the `}<inlineCode parentName="p">{`fd`}</inlineCode>{` documentation), to find all .jpg files and convert them to .png files in parallel using the Unix `}<inlineCode parentName="p">{`convert`}</inlineCode>{` command, you can run:`}</p>
    <pre><code parentName="pre" {...{}}>{`fd -e jpg -x convert {} {.}.png
`}</code></pre>
    <h3>{`Installing `}<inlineCode parentName="h3">{`fd`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS using `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install fd`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`rg`}</inlineCode>{` (ripgrep), for finding strings in files`}</h2>
    <p><inlineCode parentName="p">{`rg`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/BurntSushi/ripgrep"
      }}>{`GitHub`}</a>{`) is a (`}<em parentName="p">{`much`}</em>{`) faster alternative to `}<inlineCode parentName="p">{`grep`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/9e3bbb621ceb9d4a5a84fbc4816fe702/rg.svg",
        "alt": "rg"
      }}></img></p>
    <p><inlineCode parentName="p">{`rg`}</inlineCode>{` is written in Rust, and it powers the search functionality inside the VS Code text editor. It consistently outperforms similar tools in benchmarks.`}</p>
    <h3>{`Installing ripgrep`}</h3>
    <ul>
      <li parentName="ul">{`On macOS using `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install ripgrep`}</inlineCode></li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`Hopefully you discovered something useful in this post! I'd like this page to become an up-to-date gallery of alternative and helpful command line tools, so I may update it from time to time. If you’re interested in more content like this, follow me on `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      